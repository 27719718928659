<template>
  <b-modal
    id="modal-tingee-ocb"
    ref="refModal"
    :title="t('Tài khoản OCB')"
    :ok-title="isSubmitting ? t('Đang kiểm tra') : step === 1 ? isCoporation ? t('Tạo') : t('Lấy mã OTP') : t('Liên kết')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="6">
            <b-card
              no-body
              :class="`shadow-none border ${isCoporation ? 'bg-light-primary' : 'bg-light-secondary'} `"
              @click="onChangeCoporation(false)"
            >
              <b-card-header>
                <b-card-title>{{ t('TK cá nhân') }}</b-card-title>
                <b-form-radio
                  v-model="isCoporation"
                  name="isCoporation"
                  :value="false"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border ${!isCoporation ? 'bg-light-danger' : 'bg-light-secondary'} `"
              @click="onChangeCoporation(true)"
            >
              <b-card-header>
                <b-card-title>{{ t('TK doanh nghiệp') }}</b-card-title>
                <b-form-radio
                  v-model="isCoporation"
                  name="isCoporation"
                  :value="true"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>
        <div v-if="!isCoporation">
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  1. {{ t('Đăng ký tài khoản ngân hàng OCB') }}
                </div>
              </div>
            </b-col>
            <b-col>
              <b-alert
                show
                variant="warning"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="StarIcon"
                  />
                  <span class="ml-25">{{ t('Để kết nối dịch vụ bạn cần có tài khoản ngân hàng OCB còn hoạt động bình thường. Nếu chưa có tài khoản, hãy QUÉT MÃ QRCODE bên dưới để bắt đầu tạo tài khoản OCB.') }}</span>
                </div>
              </b-alert>
              <b-img
                :src="require('@/assets/images/qrcode/tingee_ocb.png')"
                width="240px"
                height="240px"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. {{ t('Thông tin đăng ký') }}
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">{{ t('Lưu ý') }}: <strong>{{ t('Thông tin giấy tờ tùy thân') }}</strong> {{ t('và') }} <strong>{{ t('số điện thoại') }}</strong> {{ t('phải TRÙNG KHỚP với thông tin mà bạn đã đăng ký tài khoản thanh toán tại OCB') }}:</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="customerName"
                rules="required"
              >
                <b-form-group
                  label="Họ và tên (Tiếng Việt không dấu)"
                >
                  <template
                    v-slot:label
                  >
                    {{ t('Họ và tên (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="customerName"
                    v-model="itemLocal.customerName"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="accountNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản tại OCB') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountNumber"
                    v-model="itemLocal.accountNumber"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="identificationNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Giấy tờ tùy thân (CCCD/CMND/Hộ chiếu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="identificationNumber"
                    v-model="itemLocal.identificationNumber"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="mobilePhone"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số điện thoại') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="mobilePhone"
                    v-model="itemLocal.mobilePhone"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Email
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantName"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Tên merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantName"
                    v-model="itemLocal.merchantName"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantAddress"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Địa chỉ merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantAddress"
                    v-model="itemLocal.merchantAddress"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div v-if="isCoporation">
          <b-row>

            <b-col>
              <validation-provider
                #default="validationContext"
                name="accountNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản tại OCB') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountNumber"
                    v-model="itemLocal.accountNumber"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="subId"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản ảo') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="subId"
                    v-model="itemLocal.subId"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantName"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Tên merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantName"
                    v-model="itemLocal.merchantName"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantAddress"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Địa chỉ merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantAddress"
                    v-model="itemLocal.merchantAddress"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <div
          class="mt-1"
        >

          <b-row v-if="step===2">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  {{ t('Xác thực mã OTP') }}
                </div>
              </div>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="otpCode"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Mã OTP') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="otpCode"
                    v-model="itemLocal.otpCode"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </div>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow, BCol, BAlert, BImg,
  BCard, BCardHeader, BCardTitle, BFormRadio,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useTingeeOcbModal from './useTingeeModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BImg,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const { t } = useI18nUtils();
    const {
      refModal,
      itemLocal,
      isSubmitting,
      isCoporation,
      onChangeCoporation,
      resetItemLocal,
      resetModal,
      step,
      onSubmit,
    } = useTingeeOcbModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isCoporation,
      onChangeCoporation,
      resetItemLocal,
      resetModal,
      onSubmit,
      step,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      t,
      getValidationState,
    };
  },
};
</script>
